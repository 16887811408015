@import "./reset";
@import './poly_fluid_sizing';
@import "./mixins";

$color-blue: #3B76E8;
$color-black: #1B1B1B;
$color-white: #fff;
$color-beige: #EFF1E2;
$color-grey-dark: #1C1C1C;

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


a,
a:visited,
a:active,
a:hover {
	color: inherit;
	text-decoration: underline;
}

html, body {
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	width: 100%;
	color: white;
}

body {
	min-height: 100vh;
	background: $color-beige url("../assets/noise-background-small.png");
	background-repeat: repeat;
}

.button-blue {
	max-height: 50px;
	background: $color-blue;
	color: $color-white;
	font-size: 14px;
	box-sizing: border-box;
	padding: 14px 24px;
	border-radius: 30px;
	text-decoration: none;
	line-height: 1em;
	transition: background .3s;

	&:hover {
		background: darken($color-blue, 10%);
	}

	&:visited,
	&:active,
	&:hover {
		color: #ffffff;
		text-decoration: underline;
	}

	@include bp(s) {
		font-size: 16px;
		padding: 18px 36px;
	}
}

#landing {
	width: 100%;
	color: $color-black;

	.container {
		width: 100%;
		max-width: 1440px;
		margin: 0 auto;
		padding: 0 24px;
		box-sizing: border-box;

		@include bp(s) {
			padding: 0 42px;
		}

		@include bp(m) {
			padding: 0 60px;
		}

		.row {
			display: flex;
			flex-wrap: wrap;

			@include bp(m) {
				flex-wrap: nowrap;
			}

			.col-half {
				width: 100%;

				@include bp(m) {
					width: 50%;
				}
			}
		}
	}

	.header {
		width: 100%;

		&__inner {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 20px 0;

			@include bp(s) {
				padding: 15px 0;
			}

			.logo {
				font-weight: 700;
				text-decoration: none;
				color: $color-black;
				letter-spacing: 1.67px;
			}
		}
	}

	.main	{
		padding-top: 50px;
		padding-bottom: 90px;

		@include bp(s) {
			padding-top: 90px;
			padding-bottom: 100px;
		}

		@include bp(m) {
			padding-bottom: 140px;
		}

		section {
			width: 100%;
		}

		.headline {
			position: relative;

			h1 {
				font-size: 32px;
				max-width: 956px;
				line-height: 1.125em;
				margin-left: 0;

				@include bp(s) {
					$map: (600px: 42px, 1024px: 70px, 1440px: 80px);
					@include poly-fluid-sizing('font-size', $map);
					margin-left: 120px;
				}

				@include bp(m) {
					margin-left: 180px;
				}
			}

			.icon-arrow {
				display: none;

				@include bp(s) {
					position: absolute;
					top: 16px;
					left: 0px;
					width: 40px;
					transform: none;
					display: inline-block;
				}

				@include bp(m) {
					width: 60px;
				}

				img {
					max-width: 100%;
				}
			}
		}

		.video-presentation {
			width: 100%;
			margin-top: 50px;


			@include bp(s) {
				margin-top: 130px;
			}

			.video-wrapper {
				--video--width: 1440;
				--video--height: 810;

				position: relative;
				padding-bottom: calc(var(--video--height) / var(--video--width) * 100%);
				overflow: hidden;
				max-width: 100%;
				background: black;
			}

			.video-wrapper iframe,
			.video-wrapper object,
			.video-wrapper embed {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}

		.text-presentation {
			margin-top: 50px;

			@include bp(s) {
				margin-top: 100px;
			}

			h2 {
				font-size: 24px;
				line-height: 1.17em;
				max-width: 1120px;

				@include bp(s) {
					font-size: 32px;
				}

				@include bp(m) {
					font-size: 58px;
				}

			}

			.text-wrapper {
				position: relative;
				padding-left: 0px;
				margin-top: 20px;

				@include bp(s) {
					padding-left: 150px;
					margin-top: 60px;
				}

				@include bp(m) {
					padding-left: 300px;
				}

				.icon-arrow {
					position: absolute;
					top: 10px;
					font-size: 30px;
					display: none;

					@include bp(s) {
						left: 60px;
						display: block;
						width: 24px;
					}

					@include bp(m) {
						left: 180px;
					}

					img {
						width: 100%;
					}
				}

				p {
					font-size: 16px;
					line-height: 1.46em;
					margin-bottom: 40px;
					max-width: 720px;

					@include bp(s) {
						font-size: 20px;
					}
					@include bp(m) {
						font-size: 26px;
					}

					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}
		}

		.demo-presentation {
			margin-top: 70px;
			position: relative;
			box-sizing: border-box;

			@include bp(s) {
				padding: 24px;
				margin-top: 100px;
			}

			@include bp(m) {
				padding: 0;
			}

			&__inner {
				.image-part {
					background: url('../assets/illustration-demo.png');
					background-size: cover;
					background-position: center;
					background-repeat: no-repeat;
					position: absolute;
					top: -24px;
					left: -24px;
					height: calc(100% + 48px);
					width: calc(100% + 48px);
					z-index: 4;

					@include bp(s) {
						top: 0;
						left: 0;
						height: 100%;
						width: 100%;
					}

					@include bp(m) {
						position: relative;
						top: 0;
						left: 0;
						height: 710px;
						width: 50%;
					}
				}

				.text-part {
					background: $color-grey-dark;
					padding: 32px 24px;
					box-sizing: border-box;
					position: relative;
					z-index: 6;

					@include bp(s) {
						width: 100%;
					}

					@include bp(m) {
						padding: 45px 60px 54px;
						height: 710px;
						width: 50%;
						min-width: 500px;
					}
				}

				.label {
					color: #fff;
					font-size: 20px
				}

				h2 {
					font-size: 32px;
					line-height: 1.17em;
					color: #fff;
					margin-top: 60px;
					max-width: 480px;

					@include bp(s) {
						$map: (600px: 32px, 1440px: 58px);
						@include poly-fluid-sizing('font-size', $map);
						margin-top: 150px;
					}

				}

				.text {
					color: #AEAEAE;
					font-size: 14px;
					line-height: 1.55em;
					margin-top: 20px;
					max-width: 420px;

					@include bp(s) {
						font-size: 18px;
						margin-top: 40px;
					}
				}

				.button-blue {
					margin-top: 48px;
					display: inline-block;
					max-height: 70px;
					padding: 18px 26px;
					border-radius: 40px;

					&:visited,
					&:active,
					&:hover {
						color: #ffffff;
						text-decoration: underline;
					}

					@include bp(s) {
						padding: 28px 36px;
					}
				}
			}
		}

		.what-we-learned {
			margin-top: 80px;

			@include bp(s) {
				margin-top: 100px;
			}

			@include bp(m) {
				margin-top: 160px;
			}

			.title-part {
				h2 {
					font-size: 24px;
					line-height: 1.17em;

					@include bp(s) {
						font-size: 32px;
					}

					@include bp(m) {
						font-size: 58px;
						max-width: 420px;
					}

				}
			}

			.learning-part {
				counter-reset: section;
				margin-top: 40px;

				@include bp(m) {
					margin-top: 0;
				}

				.learning {
					counter-increment: section;
					position: relative;
					padding-left: 50px;
					margin-bottom: 32px;

					strong {
						font-weight: bold;
					}

					@include bp(s) {
						padding-left: 80px;
						margin-bottom: 42px;
					}

					@include bp(m) {
						padding-left: 120px;
						margin-bottom: 70px;
					}

					&:last-child {
						margin-bottom: 0;
					}

					&:before {
						position: absolute;
						content: "0" counter(section);
						font-size: 20px;
						top: 0;
						left: 0;

						@include bp(s) {
							font-size: 32px;
						}

						@include bp(m) {
							font-size: 58px;
						}
					}

					p {
						font-size: 14px;
						line-height: 1.55em;
						max-width: 100%;

						@include bp(s) {
							font-size: 18px;
							max-width: 600px;
						}

						@include bp(m) {
							max-width: 418px;
						}
					}

				}
			}
		}
	}

	.footer {
		&__inner {
			width: 100%;
			border-top: 1px solid #CFCFCF;
			padding-top: 50px;
			padding-bottom: 60px;

			@include bp(s) {
				padding-top: 85px;
				padding-bottom: 90px;
			}

			.label {
				font-size: 14px;
				font-weight: 700;

				@include bp(s) {
					font-size: 16px;
				}
			}

			.about {
				font-size: 14px;
				line-height: 1.55em;
				max-width: 540px;
				margin-top: 12px;

				@include bp(m) {
					margin-top: 0;
					font-size: 18px;
				}
			}

			.socials {
				margin-top: 40px;

				@include bp(m) {
					margin-top: 60px;
				}

				&__label {
					font-size: 12px;
					font-weight: 700;

					@include bp(m) {
						font-weight: 400;
					}
				}

				&__list {
					margin-top: 16px;

					&-item {
						display: inline-block;
						margin-right: 30px;

						a {
							text-decoration: none;
							color: rgba($color-black, 0.5);
							font-size: 14px;
						}
					}
				}
			}
		}
	}
}
