$media: screen;
$feature: min-width;

$xs: 375px;
$s: 600px;
$m: 1024px;
$l: 1441px;


@mixin bp($breakpoint) {
	@if $breakpoint == xs {
		@media #{$media} and ($feature: $xs) {
			@content;
		}
	} @else if $breakpoint == s {
		@media #{$media} and ($feature: $s) {
			@content;
		}
	} @else if $breakpoint == m {
		@media #{$media} and ($feature: $m) {
			@content;
		}
	} @else if $breakpoint == l {
		@media #{$media} and ($feature: $l) {
			@content;
		}
	}
}
